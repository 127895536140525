var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "animated fadeIn" },
    [
      _c(
        "b-row",
        { staticClass: "pb-4" },
        [
          _c("b-col", { attrs: { cols: "12" } }, [
            _c("h2", { staticClass: "card-title m-0" }, [_vm._v(" Alertas ")]),
            _c("div", { staticClass: "small text-muted" }, [
              _vm._v(" Última atualização 5min atrás "),
            ]),
          ]),
        ],
        1
      ),
      _c("b-card", [_vm._v(" s ")]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }