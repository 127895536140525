<template>
  <div class="animated fadeIn">
    <b-row class="pb-4">
      <b-col cols="12">
        <h2 class="card-title m-0">
          Alertas
        </h2>
        <div class="small text-muted">
          Última atualização 5min atrás
        </div>
      </b-col>
    </b-row>
    <b-card>
      s
    </b-card>
  </div>
</template>

<script>
export default {
  name: 'Alerts',
}
</script>
